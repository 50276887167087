import React, { Fragment, useState, useEffect, useMemo } from "react"
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Table,
  Collapse,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

import classnames from "classnames"
import Swal from "sweetalert2"
import { Link, useParams, useLocation, useNavigate } from "react-router-dom"
import DocView from "components/DocViewer/FileDoc"
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer"
import FileConverter from "./ConvertDocx"
import Rating from "react-rating"
const Evidence = () => {
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState([])
  const [detailJadwal, setDetailJadwal] = useState([])
  const [formValuesKesimpulan, setFormValuesKesimpulan] = useState([])
  const [loading, setLoading] = useState(true)
  const [accordionOpen, setAccordionOpen] = useState([])
  const toggleAccordion = index => {
    const updatedAccordionOpen = [...accordionOpen]
    updatedAccordionOpen[index] = !updatedAccordionOpen[index]
    setAccordionOpen(updatedAccordionOpen)
  }

  const handleMouseDown = event => {
    event.preventDefault()
  }
  const [highlightedText, setHighlightedText] = useState("")
  const [showPopover, setShowPopover] = useState(false)
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 })
  const [selectedOption, setSelectedOption] = useState("")
  const [data, setData] = useState()
  const [selectedRating, setSelectedRating] = useState([])
  const [evaluasi, setEvaluasi] = useState([])
  // const [observasi, setObservasi] = useState("")
  const [count, setCount] = useState(0)
  const { idSimulasi, idJadwal, idPeserta, idSimulasiBatch } = useParams()
  const url = process.env.REACT_APP_BACKEND_URL
  const [statusFetch, setStatusFetch] = useState(false)

  const handleEvaluasi = (index, value) => {
    setEvaluasi(prevEvaluasi => {
      const updatedEvaluasi = [...prevEvaluasi]
      updatedEvaluasi[index] = { evaluasi: value }
      return updatedEvaluasi
    })
  }
  console.log(evaluasi)

  const handleRatingChange = (index, value) => {
    setSelectedRating(prevSelectedRating => {
      const updatedSelectedRating = [...prevSelectedRating]
      updatedSelectedRating[index] = {
        ...updatedSelectedRating[index],
        rating: parseInt(value),
      }
      return updatedSelectedRating
    })
  }

  const handleHighlight = () => {
    const selection = window.getSelection().toString()
    if (selection) {
      const range = window.getSelection().getRangeAt(0)
      const rect = range.getBoundingClientRect()
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop

      const span = document.createElement("span")
      span.style.backgroundColor = "#ffeb3b"
      span.appendChild(range.extractContents())

      range.insertNode(span)
      setHighlightedText(selection)
      setPopoverPosition({ top: rect.top + scrollTop - 20, left: rect.left })
      setShowPopover(true)
    }
  }

  const handleOptionClick = option => {
    setSelectedOption(option)
  }

  const closePopover = () => {
    setShowPopover(false)
  }
  const handleDataPush = (index1, index2, indikator, value, kompetensi) => {
    const data = {
      kompetensi: kompetensi,
      listEvidence: indikator,
      keterangan: highlightedText,
      idValue: indikator[0],
    }

    handleTableCellChange(index1, index2, count, highlightedText)
  }

  const handleDataPushGetData = (index1, index2, index3, value, key) => {
    handleTableCellChange(index1, index2, index3, value)
  }

  const handleTableCellChange = (index1, index2, field, value) => {
    setFormValues(prevFormValues => {
      const updatedFormValues = [...prevFormValues]
      updatedFormValues[index1] = [...(updatedFormValues[index1] || [])]
      updatedFormValues[index1][index2] = {
        ...updatedFormValues[index1][index2],
        [field]: value,
      }

      const updatedObject = {}
      let newIndex = 0
      for (const key in updatedFormValues[index1][index2]) {
        updatedObject[newIndex] = updatedFormValues[index1][index2][key]
        newIndex++
      }
      updatedFormValues[index1][index2] = updatedObject
      return updatedFormValues
    })
    setCount(prevCount => prevCount + 1)
  }

  //data kesimpulan
  const handleTableCellChangeKesimpulan = (index1, index2, field, value) => {
    console.log(index1 + index2 + field + value)
    setFormValuesKesimpulan(prevFormValues => {
      const updatedFormValues = [...prevFormValues]
      updatedFormValues[index1] = [...(updatedFormValues[index1] || [])]
      updatedFormValues[index1][index2] = {
        ...updatedFormValues[index1][index2],
        [field]: value,
      }
      return updatedFormValues
    })
  }
  const getValue = (index1, index2) => {
    if (formValues[index1] && formValues[index1][index2]) {
      //data set
      const values = Object.keys(formValues[index1][index2]).map(
        key => formValues[index1][index2][key]
      )

      return values.map((value, index) => (
        <div key={index}>
          <Row>
            <Col xl={10}>
              <div className="text-start">{value}</div>
            </Col>
            <Col xl={1}>
              <div className="text-end">
                <Button
                  className="btn btn-danger btn-sm mb-3"
                  onClick={() => handleDelete(index1, index2, index)}
                >
                  <i className="fa fa-trash"></i>
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      ))
    }
    return null
  }

  const getvalueKesimpulan = (index1, index2, key) => {
    if (
      formValuesKesimpulan[index1] &&
      formValuesKesimpulan[index1][index2] &&
      formValuesKesimpulan[index1][index2][key]
    ) {
      return formValuesKesimpulan[index1][index2][key]
    }

    console.log(index1, index2, key);
    console.log(formValuesKesimpulan);
    return ""
  }
  const cekRating = (index, rating) => {
    if (selectedRating[index] && selectedRating[index].rating) {
      if (selectedRating[index].rating == rating) {
        return true
      }
    }
    return false
  }

  const handleDelete = (index1, index2, index3) => {
    setFormValues(prevFormValues => {
      const updatedFormValues = [...prevFormValues]

      if (
        updatedFormValues[index1] &&
        updatedFormValues[index1][index2] &&
        updatedFormValues[index1][index2][index3]
      ) {
        const data = updatedFormValues[index1][index2]
        const numberOfIndexes = Object.keys(data).length

        if (numberOfIndexes === 1) {
          delete updatedFormValues[index1][index2]
        } else {
          delete updatedFormValues[index1][index2][index3]
          // Sort the remaining indices
          const remainingIndices = Object.keys(
            updatedFormValues[index1][index2]
          )
            .filter(key => key !== index3)
            .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))

          const sortedValues = {}
          remainingIndices.forEach((key, newIndex) => {
            sortedValues[newIndex] = updatedFormValues[index1][index2][key]
          })

          updatedFormValues[index1][index2] = sortedValues
        }
      }
      const filteredArray = updatedFormValues.filter(item => item !== null)
      console.log(filteredArray)

      return updatedFormValues
    })
  }

  const dataFormIvidance = () => {
    setLoading(true)
    setFormValues([0])
    const baseURL =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "penilaian/kompetensi?id_peserta=" +
      idPeserta +
      "&id_simulasi=" +
      idSimulasi +
      "&id_jadwal=" +
      idJadwal +
      "&id_batch=" +
      idSimulasiBatch
    Swal.fire({
      title: "Loading",
      text: "Please wait while we fetch the data...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })
    fetch(baseURL, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.meta.code === 200) {
          setData(responseData.data)
          const hasil = []
          const data = responseData.data

          for (let i = 0; i < data.length; i++) {
            hasil.push(data[i].mkse_kompetensi_id)
          }

          const array = responseData.data
          for (let i = 0; i < array.length; i++) {
            const item = array[i]
            const ifsEvidence = item.ifs_evidence
            const ifsKesimpulan = item.ifs_evidence_manual
            const rating = item.ifs_level
            const observasi = item.ifs_observasi
            //evidance
            if (ifsEvidence != null) {
              const arr = Object.entries(ifsEvidence).map(
                ([key, value]) => ({ key, value })
              )

              if (arr.length > 0) {
                for (let j = 0; j < arr.length; j++) {
                  const index = arr[j].value.evidence

                  console.log(index)
                  for (let k = 0; k < index.length; k++) {
                    const element = index[k]
                    handleDataPushGetData(i, j, index.length, element)
                  }
                }
              }
            }
            //kesimpulan
            if (ifsKesimpulan != null) {
              const arrKesimpulan = Object.entries(ifsKesimpulan).map(
                ([key, value]) => ({ key, value })
              )

              if (arrKesimpulan.length > 0) {
                for (let j = 0; j < arrKesimpulan.length; j++) {

                  handleTableCellChangeKesimpulan(
                    i,
                    j,
                    arrKesimpulan[j].key,
                    arrKesimpulan[j].value.kesimpulan
                  )
                }
              }
            }
            handleRatingChange(i, rating)
            handleEvaluasi(i, observasi)
          }

          console.log(formValuesKesimpulan);

          setStatusFetch(true);

          Swal.close()

          // const baseURLL =
          //   process.env.REACT_APP_BACKEND_URL_VERSION +
          //   "penilaian/evidence?id_peserta=" +
          //   idPeserta +
          //   "&id_simulasi=" +
          //   idSimulasi +
          //   "&id_jadwal=" +
          //   idJadwal +
          //   "&id_kompetensi=" +
          //   JSON.stringify(hasil)

          // var myHeaders = new Headers()
          // myHeaders.append(
          //   "Authorization",
          //   "Bearer " + localStorage.getItem("access_token")
          // )

          // var requestOptions = {
          //   method: "GET",
          //   headers: myHeaders,
          //   redirect: "follow",
          // }

          // fetch(baseURLL, requestOptions)
          //   .then(response => response.json())
          //   .then(responseData => {
          //     if (responseData.meta.code == 200) {
          //       const array = responseData.data.data
          //       for (let i = 0; i < array.length; i++) {
          //         const item = array[i]
          //         const ifsEvidence = item.ifs_evidence
          //         const ifsKesimpulan = item.ifs_evidence_manual
          //         const rating = item.ifs_level
          //         const observasi = item.ifs_observasi
          //         //evidance
          //         const arr = Object.entries(ifsEvidence).map(
          //           ([key, value]) => ({ key, value })
          //         )

          //         for (let j = 0; j < arr.length; j++) {
          //           const index = arr[j].value.evidence

          //           for (let k = 0; k < index.length; k++) {
          //             const element = index[k]
          //             handleDataPushGetData(i, j, index.length, element)
          //           }
          //         }
          //         //kesimpulan
          //         const arrKesimpulan = Object.entries(ifsKesimpulan).map(
          //           ([key, value]) => ({ key, value })
          //         )
          //         for (let j = 0; j < arrKesimpulan.length; j++) {
          //           handleTableCellChangeKesimpulan(
          //             i,
          //             j,
          //             arrKesimpulan[j].key,
          //             arrKesimpulan[j].value.kesimpulan
          //           )
          //         }
          //         handleRatingChange(i, rating)
          //         handleEvaluasi(i, observasi)
          //       }
          //       Swal.close()
          //     } else {
          //       Swal.close()
          //     }
          //   })
          //   .catch(error => {
          //     Swal.close()
          //   })
        } else {
          setLoading(false)
          Swal.close()
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseData.meta.message,
          }).then(() => { })
        }
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        Swal.close()
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Please try again later.",
        })
      })
  }
  useEffect(() => {
    dataFormIvidance()
  }, [])

  const handleSave = (index, idKompetensi) => {
    try {
      const arrayDataKet = JSON.parse(JSON.stringify(formValues[index]));
      console.log(formValuesKesimpulan);
      const arrayDatakesimpulan = JSON.parse(JSON.stringify(formValuesKesimpulan[index]));
      const dataKesimpulan = {}

      const convertedDict = {}
      let indikator = data[index].indikator_names
      console.log(indikator);
      const extractedValues = []
      for (let i = 0; i < indikator.length; i++) {
        const value = indikator[i][0]
        extractedValues.push(value)
        if (arrayDataKet[i] !== null) {
          convertedDict[value] = arrayDataKet[i]
        }
      }
      const dataKet = JSON.parse(JSON.stringify(convertedDict, null, 2))
      const convertedJson = JSON.stringify(dataKet, (key, value) =>
        typeof key === "number" ? key.toString() : value
      )

      for (let i = 0; i < arrayDatakesimpulan.length; i++) {
        const item = arrayDatakesimpulan[i]
        let key = ""
        let value = ""
        if (item !== null) {
          key = Object.keys(item)[0]
          value = item[key]
          dataKesimpulan[key] = value
        }
      }
      let observasi = "";

      try {
        observasi = evaluasi[index].evaluasi;
      } catch (error) {
        observasi = "";
      }

      for (let i = 0; i < indikator.length; i++) {
        indikator[i].splice(2, 1);
      }

      setLoading(true)
      Swal.fire({
        title: "Loading",
        text: "Please wait while we fetch the data...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })
      const array = Array.from(String(idKompetensi), Number)
      const baseURL =
        process.env.REACT_APP_BACKEND_URL_VERSION +
        "penilaian/evidence?id_peserta=" +
        idPeserta +
        "&id_simulasi=" +
        idSimulasi +
        "&id_jadwal=" +
        idJadwal +
        "&id_kompetensi=" +
        JSON.stringify(array)

      fetch(baseURL, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(responseData => {
          if (responseData.meta.code === 200) {
            const baseURL =
              process.env.REACT_APP_BACKEND_URL_VERSION +
              "penilaian/kompetensi"

            var myHeaders = new Headers()
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token"));

            const raw = JSON.stringify({
              "id_peserta": idPeserta,
              "id_simulasi": idSimulasi,
              "id_jadwal": idJadwal,
              "id_kompetensi": idKompetensi,
              "indikator": indikator,
              "ket_evidence": convertedJson,
              "kesimpulan": JSON.stringify(dataKesimpulan), // Still stringify here if necessary
              "level": selectedRating[index].rating,
              "observasi": observasi
            });

            var requestOptions = {
              method: "PUT",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            }

            fetch(baseURL, requestOptions)
              .then(response => response.json())
              .then(result => {
                setLoading(false)
                Swal.fire({
                  icon: "success",
                  title: "Data Berhasil Diupdate",
                }).then(() => {
                  Swal.close()
                  dataFormIvidance()
                })
              })
              .catch(error => {
                setLoading(false)
                Swal.close()
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong! Please try again later.",
                })
              })
            //post data jika data belum ada
          } else {
            var myHeaders = new Headers()
            myHeaders.append(
              "Authorization",
              "Bearer " + localStorage.getItem("access_token")
            )

            var formdata = new FormData()
            formdata.append("id_peserta", idPeserta)
            formdata.append("id_simulasi", idSimulasi)
            formdata.append("id_jadwal", idJadwal)
            formdata.append("id_kompetensi", idKompetensi)
            formdata.append("indikator", indikator)
            formdata.append("ket_evidence", convertedJson)
            formdata.append("kesimpulan", JSON.stringify(dataKesimpulan))
            formdata.append("level", selectedRating[index].rating)
            formdata.append("observasi", observasi)

            Swal.close()
            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: formdata,
              redirect: "follow",
            }

            fetch(
              process.env.REACT_APP_BACKEND_URL_VERSION + "penilaian/evidence",
              requestOptions
            )
              .then(response => response.text())
              .then(result => {
                setLoading(false)
                Swal.close()
                Swal.fire({
                  icon: "success",
                  title: "Data Berhasil Disimpan",
                }).then(() => {
                  dataFormIvidance()
                })
              })
              .catch(error => {
                setLoading(false)
                Swal.close()
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something we nt wrong! Please try again later.",
                })
              })
          }
        })
        .catch(error => {
          var myHeaders = new Headers()
          myHeaders.append(
            "Authorization",
            "Bearer " + localStorage.getItem("access_token")
          )

          var formdata = new FormData()
          formdata.append("id_peserta", idPeserta)
          formdata.append("id_simulasi", idSimulasi)
          formdata.append("id_jadwal", idJadwal)
          formdata.append("id_kompetensi", idKompetensi)
          formdata.append("indikator", indikator)
          formdata.append("ket_evidence", convertedJson)
          formdata.append("kesimpulan", JSON.stringify(dataKesimpulan))
          formdata.append("level", selectedRating[index].rating)
          formdata.append("observasi", observasi)

          Swal.close()
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
          }

          fetch(
            process.env.REACT_APP_BACKEND_URL_VERSION + "penilaian/evidence",
            requestOptions
          )
            .then(response => response.text())
            .then(result => {
              setLoading(false)
              Swal.close()
              Swal.fire({
                icon: "success",
                title: "Data Berhasil Disimpan",
              }).then(() => {
                dataFormIvidance()
              })
            })
            .catch(error => {
              setLoading(false)
              Swal.close()
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something we nt wrong! Please try again later.",
              })
            })
        })
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Kesimpulan evidence masih belum di isi'
      });
    }
  }

  const handleSubmit = () => {
    setLoading(true)
    Swal.fire({
      title: "Loading",
      text: "Please wait while we fetch the data...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })
    const baseURL =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "penilaian/submit?" +
      "id_peserta=" +
      idPeserta +
      "&id_simulasi=" +
      idSimulasi +
      "&id_jadwal=" +
      idJadwal

    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token")
    )

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(baseURL, requestOptions)
      .then(response => response.text())
      .then(result => {
        setLoading(false)
        Swal.close()
        setTimeout(() => {
          Swal.fire({
            icon: "success",
            title: "Data Berhasil Disimpan",
          }).then(() => {
            // navigate(`/simulasi/` + idJadwal);
            window.close();
          });
        }, 3000);
      })
      .catch(error => {
        setLoading(false)
        Swal.close()
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Please try again later.",
        })
      })
  }

  //get detail jadwal dan peserta
  useEffect(() => {
    const baseURLGet =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      `penilaian/detail?id_peserta=` +
      idPeserta +
      `&id_simulasi=` +
      idSimulasi +
      `&id_jadwal=` +
      idJadwal +
      `&id_batch=` +
      idSimulasiBatch
    fetch(baseURLGet, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.meta.code === 200) {
          setDetailJadwal(responseData.data[0].jadwal)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])



  useEffect(() => {
    if (data && data.length > 0 && formValuesKesimpulan.length == 0) {
      data.map((item, index1) => {
        const valuesArray = item.indikator_names
        valuesArray.map((value, index2) => {
          console.log("value : " + value[0])
          handleTableCellChangeKesimpulan(
            index1,
            index2,
            value[0],
            ''
          )
        })
      });
      console.log("data :")
      console.log(formValuesKesimpulan)
    }
  }, [data])

  useEffect(() => {
    if (data && data.length > 0 && selectedRating.length == 0) {
      data.map((item, index1) => {
        handleRatingChange(
          index1,
          1
        )
      })
    }
  }, [data])


  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xs={12} md={12} className="d-flex justify-content-start">
            <button
              onClick={() => navigate(`/simulasi/` + idJadwal)}
              className="btn btn-primary mb-4 rounded-3"
            >
              <i className="fa fa-arrow-left"></i> Kembali
            </button>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader className="bg-light rounded">
                <p
                  className="fw-medium"
                  onMouseDown={handleMouseDown}
                  style={{ userSelect: "none" }}
                >
                  Data Jadwal
                </p>
              </CardHeader>
              <CardBody>
                <Table>
                  <tbody>
                    <tr>
                      <th>Tanggal Mulai</th>
                      <th>:</th>
                      <td style={{ border: 'none' }}>
                        {detailJadwal &&
                          detailJadwal.mjs_tanggal_pelaksanaan_mulai}
                      </td>
                    </tr>
                    <tr>
                      <th>Tanggal Selesai</th>
                      <th>:</th>
                      <td style={{ border: 'none' }}>
                        {detailJadwal &&
                          detailJadwal.mjs_tanggal_pelaksanaan_selesai}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <Row>
              <Col>
                <Card>
                  <CardHeader className="bg-light rounded">
                    <p
                      className="fw-medium"
                      onMouseDown={handleMouseDown}
                      style={{ userSelect: "none" }}
                    >
                      Data Peserta
                    </p>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <tbody>
                        <tr>
                          <th>Nama</th>
                          <th>:</th>
                          <td style={{ border: 'none' }}>{detailJadwal && detailJadwal.nama_peserta}</td>
                        </tr>
                        <tr>
                          <th>Nip</th>
                          <th>:</th>
                          <td style={{ border: 'none' }}>{detailJadwal && detailJadwal.nip}</td>
                        </tr>
                        <tr>
                          <th>Jabatan</th>
                          <th>:</th>
                          <td style={{ border: 'none' }}>{detailJadwal && detailJadwal.jabatan}</td>
                        </tr>
                        <tr>
                          <th>Instansi</th>
                          <th>:</th>
                          <td style={{ border: 'none' }}>{detailJadwal && detailJadwal.nama_instansi}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <div onMouseUp={handleHighlight}>
                  <FileConverter
                    nama_simulasi={detailJadwal.mjs_simulasi}
                    idJadwal={idJadwal}
                    idSimulasi={idSimulasi}
                    idPeserta={idPeserta}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xl={6}>
            <div className="accordion" id="accordion-1">
              {setStatusFetch && data &&
                data.map((item, index1) => {
                  return (
                    <div className="accordion-item" key={index1}>
                      <h2 className="accordion-header" id={`heading-${index1}`}>
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            {
                              collapsed: !accordionOpen[index1],
                            }
                          )}
                          type="button"
                          onClick={() => toggleAccordion(index1)}
                          style={{ cursor: "pointer" }}
                        >
                          {item.mka_kompetensi}
                        </button>
                      </h2>

                      <Collapse
                        isOpen={accordionOpen[index1]}
                        className="accordion-collapse collapse"
                        id={`collapse-${index1}`}
                        aria-labelledby={`heading-${index1}`}
                      >
                        <div className="accordion-body">
                          <Row>
                            <Col>
                              <Row xl={12}>
                                <Card>
                                  <CardBody>
                                    <p className="fw-bold fs-5">Observasi</p>
                                    <textarea
                                      className="form-control"
                                      value={
                                        evaluasi &&
                                          evaluasi[index1] &&
                                          evaluasi[index1].evaluasi
                                          ? evaluasi[index1].evaluasi
                                          : ""
                                      }
                                      onChange={e =>
                                        handleEvaluasi(index1, e.target.value)
                                      }
                                    ></textarea>
                                  </CardBody>
                                </Card>
                              </Row>
                            </Col>
                            <Table className="table table-striped table-bordered">
                              <thead className="table-light">
                                <tr>
                                  <th scope="col">List Evidance Indikator</th>
                                  <th scope="col">Keterangan Evidance</th>
                                  <th scope="col">Kesimpulan Evidance</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(() => {
                                  // const parsedJson = JSON.parse(
                                  //   item.ifs_indikator
                                  // )
                                  const valuesArray = item.indikator_names
                                  valuesArray.sort((a, b) => a - b)
                                  return valuesArray.map((value, index2) => (
                                    <tr key={index2}>
                                      <td style={{ border: 'none' }}>{value[1]}</td>
                                      <td style={{ border: 'none' }}>{getValue(index1, index2)}</td>
                                      <td style={{ border: 'none' }}>
                                        <textarea
                                          required
                                          value={getvalueKesimpulan(
                                            index1,
                                            index2,
                                            value[0]
                                          )}
                                          className="form-control"
                                          onChange={e =>
                                            handleTableCellChangeKesimpulan(
                                              index1,
                                              index2,
                                              value[0],
                                              e.target.value
                                            )
                                          }
                                        ></textarea>
                                      </td>
                                    </tr>
                                  ))
                                })()}
                              </tbody>
                            </Table>
                          </Row>
                          <Row className="mt-3">
                            <div>
                              <Card>
                                <CardHeader className="bg-light fw-medium">
                                  Pilih Level Kompetensi
                                </CardHeader>
                                <CardBody>
                                  <Form className="text-center">
                                    <FormGroup row>
                                      <Col xl={12}>
                                        {Array.from(
                                          {
                                            length: parseInt(
                                              detailJadwal.std_level
                                            ),
                                          },
                                          (_, index) => index + 1
                                        ).map(rating => (
                                          <Label
                                            key={rating}
                                            check
                                            className="me-3"
                                          >
                                            <Input
                                              type="radio"
                                              name={`rating${index1}`}
                                              value={rating}
                                              checked={cekRating(
                                                index1,
                                                rating
                                              )}
                                              onClick={e =>
                                                handleRatingChange(
                                                  index1,
                                                  e.target.value
                                                )
                                              }
                                            />
                                            Level {rating}
                                          </Label>
                                        ))}
                                      </Col>
                                    </FormGroup>
                                  </Form>
                                </CardBody>
                              </Card>
                            </div>
                          </Row>
                          <Row>
                            <Col xl={12} className="text-end">
                              {selectedRating[index1] &&
                                selectedRating[index1].rating &&
                                selectedRating[0] && (
                                  <button
                                    className="btn-primary btn rounded"
                                    onClick={() =>
                                      handleSave(
                                        index1,
                                        item.mkse_kompetensi_id
                                      )
                                    }
                                  >
                                    Simpan
                                  </button>
                                )}
                            </Col>
                          </Row>
                        </div>
                      </Collapse>
                    </div>
                  )
                })}
              <Row>
                <Col>
                  <Row
                    xl={4}
                    className="align-item-end justify-content-end mt-2 mx-4"
                  >
                    <button
                      className="btn btn-primary"
                      onClick={() => handleSubmit()}
                    >
                      Selesai
                    </button>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {showPopover && (
          <div
            className="my-popover"
            style={{ top: popoverPosition.top, left: popoverPosition.left }}
          >
            <div className="popover-content">
              <h3>Highlighted Text:</h3>
              <ul className="popover-options">
                {data &&
                  data.map((item, index1) => {
                    return (
                      <li
                        onClick={() => handleOptionClick("Option" + index1)}
                        className={
                          selectedOption === "Option" + index1 ? "selected" : ""
                        }
                        key={index1}
                      >
                        {item.mka_kompetensi}
                        <div className="choices">
                          {selectedOption === "Option" + index1 &&
                            (() => {
                              const valuesArray = item.indikator_names
                              return valuesArray.map((value, index2) => (
                                <span
                                  key={index2}
                                  onClick={() =>
                                    handleDataPush(
                                      index1,
                                      index2,
                                      value,
                                      item.nama_kompetensi
                                    )
                                  }
                                >
                                  {value[1] + ' . ' + value[2]}
                                </span>
                              ))
                            })()}
                        </div>
                      </li>
                    )
                  })}
              </ul>
            </div>
            <div className="popover-arrow"></div>
            <button className="close-button" onClick={closePopover}>
              Close
            </button>
          </div>
        )}
      </Container>
    </div>
  )
}

export default Evidence
